<template>
  <div class="exchange-currency-details-container wrapper">
    <CRow class>
      <CCol lg="9" md="12">
        <CCard>
          <CCardHeader>
            <router-link :to="exchangeCurrencyListUrl">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_BACK_LINK_BUTTON') }}
            </router-link>
          </CCardHeader>

          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody>
            <ValidationObserver ref="form" v-if="!isLoading" v-slot="{ handleSubmit, dirty }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_NAME_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_NAME_FIELD_NAME" rules="required|max:128">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.name" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_ICON_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_ICON_FIELD_NAME" rules="required|max:256">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.icon" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_SYMBOL_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_SYMBOL_FIELD_NAME" rules="required|max:128|alpha_num">
                      <div slot-scope="{ errors }">
                        <input type="text" v-model="currencyDetails.symbol" class="form-control" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_PLATFORM_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_PLATFORM_FIELD_NAME" rules="required|max:128|alpha_num">
                      <div slot-scope="{ errors }">
                        <input
                          @change="validContractAddress(currencyDetails.platform, currencyDetails.contract_address)"
                          type="text"
                          v-model="currencyDetails.platform"
                          class="form-control"
                        />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_STATUS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_STATUS_FIELD_NAME" rules="required">
                      <div slot-scope="{ errors }">
                        <select v-model="currencyDetails.status" class="form-control">
                          <option value>{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_CHOSE_A_STATUS_LABEL') }}</option>
                          <option v-for="item in statuses" :key="item.value" :value="item.value">{{ item.label }}</option>
                        </select>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_MS_STATUS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_MS_STATUS_FIELD_NAME" rules="required">
                      <div slot-scope="{ errors }">
                        <select v-model="currencyDetails.ms_status" class="form-control">
                          <option value>{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_CHOSE_A_MS_STATUS_LABEL') }}</option>
                          <option v-for="item in statuses" :key="item.value" :value="item.value">{{ item.label }}</option>
                        </select>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_DECIMALS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_DECIMALS_FIELD_NAME" rules="min_value:1">
                      <div slot-scope="{ errors }">
                        <currency-input class="input-field form-control" v-model="currencyDetails.decimals" :precision="0" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_ORDER_INDEX_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_ORDER_INDEX_FIELD_NAME" rules>
                      <div slot-scope="{ errors }">
                        <currency-input class="input-field form-control" v-model="currencyDetails.order_index" :precision="0" />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_FROM_FLAG_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_FROM_FLAG_FIELD_NAME">
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input type="checkbox" class="checkbox" v-model="currencyDetails.from_flg" />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_TO_FLAG_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_TO_FLAG_FIELD_NAME">
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input type="checkbox" class="checkbox" v-model="currencyDetails.to_flg" />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_CONTRACT_ADDRESS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_CONTRACT_ADDRESS_FIELD_NAME">
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input
                              @change="validContractAddress(currencyDetails.platform, currencyDetails.contract_address)"
                              type="checkbox"
                              class="checkbox"
                              v-model="currencyDetails.contract_flg"
                            />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow v-if="currencyDetails.contract_flg" class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_CONTRACT_ADDRESS_LABEL') }}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_CONTRACT_ADDRESS_FIELD_NAME" rules="max:256">
                      <div slot-scope="{ errors }">
                        <input
                          @change="validContractAddress(currencyDetails.platform, currencyDetails.contract_address)"
                          type="text"
                          v-model="currencyDetails.contract_address"
                          class="form-control"
                        />
                        <span class="error-msg-wrap">
                          <span v-if="!isValidContractAddress" class="error-msg">{{
                            $t('EXCHANGE_CURRENCY_DETAILS_PAGE_CONTRACT_ADDRESS_INVALID')
                          }}</span>
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_FIXED_RATE_LABEL') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_FIXED_RATE_FIELD_NAME">
                      <div>
                        <div class="btn-switch-wrapper">
                          <label class="btn-switch">
                            <input type="checkbox" class="checkbox" v-model="currencyDetails.fix_rate_flg" />
                            <span class="check-silder"></span>
                          </label>
                        </div>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('EXCHANGE_CURRENCY_DETAILS_PAGE_DESCRIPTION_LABEL') }}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <ValidationProvider name="EXCHANGE_CURRENCY_DETAILS_PAGE_DESCRIPTION_FIELD_NAME" rules="max:1000">
                      <div slot-scope="{ errors }">
                        <textarea v-model="currencyDetails.description" rows="5" class="form-control"></textarea>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-actions mt-3">
                  <CCol lg="12" md="12">
                    <CButton @click="cancel(dirty)" v-if="!isEdit || (isEdit && isAllowedUpdating)" class="btn btn-secondary btn-cancel">{{
                      $t('EXCHANGE_CURRENCY_DETAILS_PAGE_CANCEL_BUTTON')
                    }}</CButton>

                    <CButton @click="onSubmit()" v-if="!isEdit" color="primary" class="btn btn-update btn-create">{{
                      $t('EXCHANGE_CURRENCY_DETAILS_PAGE_CREATE_BUTTON')
                    }}</CButton>

                    <CButton @click="onSubmit()" v-if="isEdit && isAllowedUpdating" color="primary" class="btn btn-update">{{
                      $t('EXCHANGE_CURRENCY_DETAILS_PAGE_UPDATE_BUTTON')
                    }}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>

        <StakingModal :linkTo="exchangeCurrencyListUrl" :warningLeavePage="warningLeavePage" @close-modal="closeModal" />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import currencyHelper from '@/utils/currency-helper';
import { Constants, env } from '@/constants';
import StakingModal from '@/components/StakingModal';
import verifyAddress from '@/utils/verify-address';

export default {
  name: 'EditExchangeCurrency',
  components: {
    VclList,
    StakingModal,
  },
  data() {
    return {
      currencyId: null,
      currencyDetails: null,
      statuses: [],
      isEdit: false,
      isLoading: true,
      isAllowedUpdating: false,
      warningLeavePage: false,
      exchangeCurrencyListUrl: '/admin/exchange/currencies',
      isValidContractAddress: true,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    const currencyId = this.$route.params.currencyId;
    this.checkPermissions();

    if (currencyId) {
      this.isEdit = true;
      this.currencyId = currencyId;
      await Promise.all([this.getExchangeCurrencyDetails(currencyId), this.getExchangeCurrencyStatuses()]);
      this.isLoading = false;
    } else {
      this.isEdit = false;
      await Promise.all([this.getExchangeCurrencyStatuses()]);
      this.currencyDetails = {
        contract_flg: false,
        from_flg: false,
        to_flg: false,
        fix_rate_flg: false,
      };
      this.isLoading = false;
    }
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach((item) => {
          if (item.name === 'UPDATE_EXCHANGE_CURRENCY') {
            this.isAllowedUpdating = true;
          }
        });
      }
    },
    async getExchangeCurrencyStatuses() {
      try {
        const result = await this.$http.get(endpoints.getExchangeCurrencyStatuses);
        this.statuses = stringHelper.translateTextForDropdownList(result.data, 'EXCHANGE_CURRENCY_STATUS');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EXCHANGE_CURRENCY_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getExchangeCurrencyDetails(currencyId) {
      try {
        const res = await this.$http.get(endpoints.getExchangeCurrencyDetails(currencyId));
        this.currencyDetails = res.data;

        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EXCHANGE_CURRENCY_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    backToExchangeCurrencyList() {
      this.$router.push(this.exchangeCurrencyListUrl);
    },
    closeModal(val) {
      this.warningLeavePage = val;
    },
    cancel(dirty) {
      if (dirty) {
        this.warningLeavePage = true;
      } else {
        this.$router.push(this.exchangeCurrencyListUrl);
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      if (this.currencyDetails.contract_flg && !this.isValidContractAddress) {
        return;
      }

      if (this.isEdit) {
        return this.updateExchangeCurrency();
      }

      this.createExchangeCurrency();
    },
    async updateExchangeCurrency() {
      if (this.currencyDetails.isProcessing) {
        return;
      }

      this.currencyDetails.isProcessing = true;

      try {
        const data = {
          name: this.currencyDetails.name,
          symbol: this.currencyDetails.symbol,
          platform: this.currencyDetails.platform,
          icon: this.currencyDetails.icon,
          decimals: this.currencyDetails.decimals || null,
          order_index: this.currencyDetails.order_index,
          description: this.currencyDetails.description,
          contract_address: this.currencyDetails.contract_address,
          contract_flg: this.currencyDetails.contract_flg,
          status: this.currencyDetails.status,
          ms_status: this.currencyDetails.ms_status,
          from_flg: this.currencyDetails.from_flg || false,
          to_flg: this.currencyDetails.to_flg || false,
          fix_rate_flg: this.currencyDetails.fix_rate_flg || false,
        };
        const result = await this.$http.put(endpoints.updateExchangeCurrency(this.currencyId), data);

        this.currencyDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('EXCHANGE_CURRENCY_DETAILS_PAGE_NOTIFICATION_SUCCESS_TITLE'),
          text: this.$t('EXCHANGE_CURRENCY_DETAILS_PAGE_UPDATE_EXCHANGE_CURRENCY_SUCCESS'),
        });
        this.backToExchangeCurrencyList();
      } catch (err) {
        this.currencyDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EXCHANGE_CURRENCY_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async createExchangeCurrency() {
      if (this.currencyDetails.isProcessing) {
        return;
      }

      this.currencyDetails.isProcessing = true;
      try {
        const data = {
          name: this.currencyDetails.name,
          symbol: this.currencyDetails.symbol,
          platform: this.currencyDetails.platform,
          icon: this.currencyDetails.icon,
          decimals: this.currencyDetails.decimals || null,
          order_index: this.currencyDetails.order_index,
          description: this.currencyDetails.description,
          contract_address: this.currencyDetails.contract_address,
          status: this.currencyDetails.status,
          ms_status: this.currencyDetails.ms_status,
          contract_flg: this.currencyDetails.contract_flg || false,
          from_flg: this.currencyDetails.from_flg || false,
          to_flg: this.currencyDetails.to_flg || false,
          fix_rate_flg: this.currencyDetails.fix_rate_flg || false,
        };
        const result = await this.$http.post(endpoints.createExchangeCurrency, data);

        this.currencyDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('EXCHANGE_CURRENCY_DETAILS_PAGE_NOTIFICATION_SUCCESS_TITLE'),
          text: this.$t('EXCHANGE_CURRENCY_DETAILS_PAGE_CREATE_EXCHANGE_CURRENCY_SUCCESS'),
        });

        this.backToExchangeCurrencyList();
      } catch (err) {
        this.currencyDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EXCHANGE_CURRENCY_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    validContractAddress(platform, contractAddress) {
      try {
        if (platform !== ('' || undefined)) {
          this.isValidContractAddress = verifyAddress.multiAddress(platform.toUpperCase(), contractAddress);
        } else {
          this.isValidContractAddress = true;
        }
      } catch (error) {
        console.log(' Missing validator for currency');
      }
    },
  },
};
</script>
<style lang="scss">
.exchange-currency-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #695fe5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
      }
    }

    .form-control-plaintext {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }

    .form-group {
      margin-bottom: 0.5rem;
      .col-form-label {
        width: 100%;
        text-align: right;
      }

      .btn-switch-wrapper {
        margin-top: 10px;
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 32px;
        width: 200px;
        height: 32px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        height: 36px;

        &.btn-primary {
          background: #3b4b7e;
        }

        &.btn-update {
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.5px;
          text-align: center;
          background-color: #3b4b7e;
          border: 1px solid #3b4b7e;
          border-radius: 4px;
        }

        &.btn-cancel {
          background-color: transparent;
          color: #6c65e4;
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }
  }
}
</style>
